import mainjson from './main';
import { Post } from "../utils/ApiFunctions";


async function send(id) {
    if (confirm("Are you certain you want to send this message") == true) {
        let ret = await Post("sendpush",{pushid:id});
        return "refreshlist";
    } 
    
}


export default function GetData() {
        return {
        "apitype":"pushmessages",
        "id":"pushmessage_id",
        "subidname":"pushmessage_title",
        "options":["edit","insert","delete"],
        "nameSingle":"push message",
        "nameMultiple":"push messages",
        "filters":
            [
               
            ]
        ,
        "extraoptions":[
            {
                "name":"Send",
                "method":send,
                "if":{
                    true:[{"key":"pushmessage_sent","value":"0"}],
                }
            }
        ],


        "fields":{
            "field1":{
                "name":"Subject",
                "field":"pushmessage_title",
                "max":65,
                "type":"TextInputMultiLanguage",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Message",
                "field":"pushmessage_body",
                "type":"TextAreaMultiLanguage",
                "required":true,
                "max":240,
            },
            "field3":{
                "name":"To users on event",
                "field":"pushmessage_event_id",
                "emptyfirstoption":"All users",
                "emptyfirstoptionvalue":"-1",
                "type":"DropDown",
                "options": "events",
                "list":true,
            }, 
            "field4":{
                "name":"Sent",
                "field":"pushmessage_sent",
                "type":"DropDown",
                "options": {
                    "0":"No",
                    "1":"Yes",
                },
                "edit":false,
                "list":true,
            },      
        }
    }
}